import { FixedDevice, MobileDevice } from "./deviceTypes";

export const createMmsString = (device: FixedDevice | MobileDevice) => {
  let mms = [device.deviceType, device.make, device.model]
    .join(",")
    .toUpperCase();

  if (device.serialNumber) {
    mms += `,${device.serialNumber}`;
  }
  return mms;
};

export const deviceSupportsSnmpV3 = (device: FixedDevice) =>
  ["RSU"].includes(device.deviceType);

export const deviceSupportsSsh = (device: FixedDevice) =>
  ["RSU"].includes(device.deviceType);
