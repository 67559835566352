import React from "react";

export interface InterfaceTab {
  disabled?: boolean;
  title: string;
  badge?: number;
  content: JSX.Element;
}

const TabPanel = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    activeTab: number;
    tabs: InterfaceTab[];
    setActiveTab: (tab: number) => void;
  }
) => {
  const { activeTab, tabs, setActiveTab, ...rest } = props;
  // If the active tab exceeds the allowed range (such as when switching from the second tab of a 2-tab panel to a single-tab panel), reset it to 0.
  React.useEffect(() => {
    if (activeTab < 0 || activeTab >= tabs.length) {
      throw new Error(`activeTab value of ${activeTab} not allowed for this TabPanel. Only values between 0 and ${tabs.length - 1} inclusive are allowed.`);
    }
  }, [activeTab, tabs, setActiveTab]);

  return (
    <div className="tab-panel-container" {...rest}>
      <div className="tab-panel-header">
        {tabs.map(({ disabled, title, badge }, index) => (
          <div className={`tab${index === activeTab ? " active" : ""}${disabled ? " disabled" : ""}`}
            key={index}
            onClick={() => {
              if (disabled) return;

              setActiveTab(index);
            }}
            title={`Switch to the ${title} tab${disabled ? ' (disabled)' : ''}`}
          >
            <span className="tab-name">
              {title}
            </span>
            {badge && <span className="tab-badge">{badge}</span>}
          </div>
        ))}
      </div>
      <div className="tab-panel-content">
        {/* Render null for invalid tabs so we don't immediately crash when the tab exceeds the range before it can be changed back to 0. */}
        {tabs[activeTab] ? tabs[activeTab].content : null}
      </div>
    </div>
  );
};
export default TabPanel;
