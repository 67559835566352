import React from "react";
import { MobileDevice, NormalizedVehicle } from "../../lib/devices/deviceTypes";
import Modal from "../Modal";
import Button from "../controls/Button";

export const VehicleUnenrollmentModal = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    confirmAction: (vehicle: NormalizedVehicle) => void;
    cancelAction: (vehicle: NormalizedVehicle) => void;
    vehicle: NormalizedVehicle;
    devices: MobileDevice[];
  }
) => {
  const { confirmAction, cancelAction, vehicle, devices, ...rest } = props;

  return (
    <Modal
      {...rest}
      className="light"
      footer={
        <div style={{ display: "flex", gap: 18 }}>
          <Button
            className="action lg"
            disabled={false}
            onClick={() =>
              cancelAction(vehicle)
            }
            style={{ marginTop: 32 }}
          >
            CANCEL
          </Button>
          <Button
            className="action lg"
            disabled={false}
            onClick={() =>
              confirmAction(vehicle)
            }
            style={{ marginTop: 32 }}
          >
            CONFIRM
          </Button>
        </div>
      }
      header={<h2>Unenroll</h2>}
    >
      <div style={{ marginTop: 6, marginBottom: 24 }}>
        <div>
          {`By unenrolling the vehicle, this vehicle (${vehicle.vin}) and the following devices will be removed from the view:`}
        </div>
        <br />
        <ul style={{ justifyItems: "start" }}>
          {devices.map((device) => <li>
            {`- ${device.serialNumber} (Device Id: ${device.id}}`}
          </li>)}
        </ul>
      </div>
    </Modal>
  );
};