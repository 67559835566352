import React from "react";

import Modal from "../Modal";
import Button from "../controls/Button";
import Input from "../controls/Input";

import { generateRandomString } from "../../lib/generate";

import { NormalizedVehicle } from "../../lib/devices/deviceTypes";
import Toggle from "../controls/Toggle";

const VehicleModal = ({
  confirmAction,
  headerText,
  vehicle: prevVehicle,
  vehicleVins,
}: {
  confirmAction: (vehicle: NormalizedVehicle) => void;
  headerText: string;
  vehicle?: NormalizedVehicle;
  vehicleVins: string[];
}) => {
  const prevVehicleVin = prevVehicle?.vin ?? "";
  const prevVehicleMake = prevVehicle?.make ?? "";
  const prevVehicleModel = prevVehicle?.model ?? "";
  const prevVehicleYear = prevVehicle?.year ?? "";
  const prevVehiclePlate = prevVehicle?.plate ?? "";
  const prevVehicleType = prevVehicle?.type ?? "";
  const prevVehicleFleetId = prevVehicle?.fleetId ?? "";
  const prevVehicleEnrolled = prevVehicle?.enrolled ?? true;

  const [nextVehicleVin, setNextVehicleVin] = React.useState(prevVehicleVin);
  const [nextVehicleMake, setNextVehicleMake] = React.useState(prevVehicleMake);
  const [nextVehicleModel, setNextVehicleModel] = React.useState(prevVehicleModel);
  const [nextVehicleYear, setNextVehicleYear] = React.useState(prevVehicleYear);
  const [nextVehiclePlate, setNextVehiclePlate] = React.useState(prevVehiclePlate);
  const [nextVehicleType, setNextVehicleType] = React.useState(prevVehicleType);
  const [nextVehicleFleetId, setNextVehicleFleetId] = React.useState(prevVehicleFleetId);
  const [nextVehicleEnrolled, setNextVehicleEnrolled] = React.useState(prevVehicleEnrolled);

  const vehicleVinError = !nextVehicleVin.trim().length
    ? "*Vehicle VIN is required"
    : prevVehicle?.vin !== nextVehicleVin && vehicleVins.includes(nextVehicleVin)
      ? "Vehicle VIN is already taken"
      : !/^[A-HJ-NPR-Z0-9]{17}$/.test(nextVehicleVin)
        ? "VIN must be exactly 17 characters long and must not contain I, O, and Q"
        : "";

  return (
    <Modal
      className="light"
      footer={
        <Button
          className="action lg"
          disabled={
            !!vehicleVinError ||
            (prevVehicleVin === nextVehicleVin &&
              prevVehicleMake === nextVehicleMake &&
              prevVehicleModel === nextVehicleModel &&
              prevVehicleYear === nextVehicleYear &&
              prevVehiclePlate === nextVehiclePlate &&
              prevVehicleType === nextVehicleType &&
              prevVehicleFleetId === nextVehicleFleetId &&
              prevVehicleEnrolled === nextVehicleEnrolled)
          }
          onClick={() =>
            confirmAction({
              deviceIds: prevVehicle?.deviceIds ?? [],
              id: prevVehicle?.id ?? generateRandomString(),
              vin: nextVehicleVin,
              make: nextVehicleMake,
              model: nextVehicleModel,
              year: nextVehicleYear ? nextVehicleYear as number : null,
              plate: nextVehiclePlate,
              type: nextVehicleType,
              fleetId: nextVehicleFleetId,
              enrolled: nextVehicleEnrolled,
            })
          }
          style={{ marginTop: 32 }}
        >
          CONFIRM
        </Button>
      }
      header={<h2>{headerText}</h2>}
    >
      <div
        style={{ width: "100%", height: 80, marginTop: 6, marginBottom: 24 }}
      >
        <Input
          autoFocus
          changed={!headerText.includes("New") && prevVehicleVin !== nextVehicleVin}
          id="vehicle-vin"
          label="VIN"
          onChange={(e) => setNextVehicleVin(e.target.value.slice(0, 200))}
          required
          type="text"
          validate={() => vehicleVinError}
          value={nextVehicleVin}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevVehicleMake !== nextVehicleMake}
          id="vehicle-make"
          label="Make"
          maxLength={200}
          onChange={(e) =>
            setNextVehicleMake(e.target.value.slice(0, 200))
          }
          type="text"
          value={nextVehicleMake}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevVehicleModel !== nextVehicleModel}
          id="vehicle-model"
          label="Model"
          maxLength={200}
          onChange={(e) =>
            setNextVehicleModel(e.target.value.slice(0, 200))
          }
          type="text"
          value={nextVehicleModel}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={
            !headerText.includes("New") && prevVehicleYear !== nextVehicleYear
          }
          id="year"
          label="Year"
          max={9999}
          min={1885}
          onChange={(e) => {
            if (e.target.value.length > 4) {
              e.target.value = e.target.value.slice(0, 4)
            }
            const nextValue = parseInt(e.target.value);

            setNextVehicleYear(
              e.target.value !== "-" && Number.isNaN(nextValue)
                ? ("" as any)
                : nextValue
            );
          }}
          placeholder="e.g. 2024"
          step={1}
          type="number"
          value={nextVehicleYear}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevVehiclePlate !== nextVehiclePlate}
          id="vehicle-plate"
          label="Plate"
          maxLength={200}
          onChange={(e) =>
            setNextVehiclePlate(e.target.value.slice(0, 200))
          }
          placeholder="e.g. CO XYZ-123"
          type="text"
          value={nextVehiclePlate}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevVehicleType !== nextVehicleType}
          id="vehicle-type"
          label="Type"
          maxLength={200}
          onChange={(e) =>
            setNextVehicleType(e.target.value.slice(0, 200))
          }
          type="text"
          value={nextVehicleType}
        />
        <p>
          Vehicle Type describes the role the vehicle serves, such as transit, ambulance, fire, etc.
        </p>
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevVehicleFleetId !== nextVehicleFleetId}
          id="vehicle-fleet-id"
          label="Fleet ID"
          maxLength={200}
          onChange={(e) =>
            setNextVehicleFleetId(e.target.value.slice(0, 200))
          }
          type="text"
          value={nextVehicleFleetId}
        />
      </div>
      <div>
        <label>Vehicle Enrollment</label>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Toggle
            disabled={!prevVehicle}
            changed={
              !headerText.includes("New") && prevVehicleEnrolled !== nextVehicleEnrolled
            }
            checked={nextVehicleEnrolled}
            id="enrolled"
            onChange={() => setNextVehicleEnrolled(!nextVehicleEnrolled)}
          />
          <div style={{ marginLeft: 18 }}>
            <label htmlFor="enrolled" style={{ cursor: "pointer" }}>
              {nextVehicleEnrolled ? "" : "Not "}Enrolled
            </label>
            <p style={{ marginTop: 4 }}>
              Enrolled Vehicles are Vehicles that Cirrus should track and
              communicate with. Enrolled Vehicles may incur service charges according
              to usage and plan details.
              <b style={{ fontWeight: 750 }}> Unenrolled Vehicle configurations will be removed
                from the view and cause all associated devices to be unenrolled and removed from view as well.
              </b>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const RegisterNewVehicleModal = ({
  confirmAction,
  vehicleVins,
}: {
  confirmAction: (vehicle: NormalizedVehicle) => void;
  vehicleVins: string[];
}) => (
  <VehicleModal
    confirmAction={confirmAction}
    headerText="Register New Vehicle"
    vehicleVins={vehicleVins}
  />
);

export const EditVehicleModal = ({
  confirmAction,
  vehicle,
  vehicleVins,
}: {
  confirmAction: (vehicle: NormalizedVehicle) => void;
  vehicle: NormalizedVehicle;
  vehicleVins: string[];
}) => (
  <VehicleModal
    confirmAction={confirmAction}
    headerText="Edit Vehicle"
    vehicle={vehicle}
    vehicleVins={vehicleVins}
  />
);