import React, { CSSProperties } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { useAuth } from "../../state/authState";
import { useUI } from "../../state/uiState";

import Loader from "../Loader";
import Button from "../controls/Button";
import Input from "../controls/Input";

import {
  API_ABORT_MESSAGE,
  registerNewVehicle,
  editVehicle,
  fetchMobileAssets,
  registerNewDevice,
  editDevice,
  removeDevice,
} from "../../lib/api";
import { getMobileDeviceTypeLabel } from "../../lib/v2x/v2xUtils";

import config, { serviceBasePaths } from "../../config";

import ArrowBackIcon from "../../assets/icons/arrowBack.svg";

import { MobileDevice, NormalizedVehicle } from "../../lib/devices/deviceTypes";
import { EditVehicleModal, RegisterNewVehicleModal } from "./VehicleManagerModals";
import { EditMobileDeviceModal, RegisterNewMobileDeviceModal, RemoveMobileDeviceModal } from "./MobileDeviceModals";
import { createMmsString } from "../../lib/devices/deviceUtils";
import { VehicleUnenrollmentModal } from "./VehicleUnenrollmentModal";

const editVehicleWidth = 89, viewDetailWidth = 88;

const VehicleRow = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    devices: { [deviceId: string]: MobileDevice };
    isLoading: boolean;
    showViewDetail: boolean;
    vehicle: NormalizedVehicle;
    setVehicle?: (newVehicle: string) => void;
  }
) => {
  const { setOverlay } = useUI();

  const { devices, isLoading, showViewDetail, vehicle, setVehicle, ...rest } = props;

  return (
    <div
      style={{
        display: "flex",
        gap: 18,
        alignItems: "center",
        width: "100%",
        height: "75px",
        marginBottom: "12px",
        padding: "12px 16px 12px 24px",
        borderRadius: "8px",
        backgroundColor: "#1e1e1e",
        fontSize: "14px",
      }}
      {...rest}
    >
      <div
        style={{
          flex: "0 1 100px",
          minWidth: 75,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={vehicle.fleetId}
      >
        <span>{vehicle.fleetId}</span>
      </div>
      <div
        style={{
          flex: "0 1 160px",
          minWidth: 160,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={vehicle.vin}
      >
        <span>{vehicle.vin}</span>
      </div>
      <div
        style={{
          flex: "0 1 100px",
          minWidth: 75,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={vehicle.make}
      >
        <span>{vehicle.make}</span>
      </div>
      <div
        style={{
          flex: "0 1 100px",
          minWidth: 75,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={vehicle.model}
      >
        <span>{vehicle.model}</span>
      </div>
      <div
        style={{
          flex: "0 1 70px",
          minWidth: 70,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={vehicle.year?.toString()}
      >
        <span>{vehicle.year}</span>
      </div>
      <div
        style={{
          flex: "0 1 70px",
          minWidth: 70,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={vehicle.plate}
      >
        <span>{vehicle.plate}</span>
      </div>
      <div
        style={{
          flex: "0 1 250px",
          minWidth: 75,
          overflowX: "hidden",
          textOverflow: "ellipsis",
        }} title={vehicle.id}>
        <span
          style={{
            maxHeight: "34px",
          }}
        >
          {vehicle.id}
        </span>
      </div>
      <div
        style={{
          flex: "0 1 25px",
          minWidth: 25,
        }}
        title={vehicle.deviceIds
          .filter((deviceId) => devices[deviceId].enrolled)
          .length.toString()}
      >
        <span>
          {
            vehicle.deviceIds.filter((deviceId) => devices[deviceId].enrolled)
              .length
          }
        </span>
      </div>
      <div
        style={{
          flex: `0 1 ${showViewDetail ? editVehicleWidth : editVehicleWidth + viewDetailWidth}px`,
          display: "flex",
          justifyContent: "right",
          minWidth: showViewDetail ? editVehicleWidth : editVehicleWidth + viewDetailWidth,
          maxWidth: showViewDetail ? editVehicleWidth : editVehicleWidth + viewDetailWidth,
        }}
        title="Edit Vehicle"
      >
        <Button
          className="inline"
          disabled={isLoading || vehicle.type === "Unknown"}
          onClick={() => setOverlay(`editVehicle:${vehicle.id}`)}
        >
          Edit Vehicle
        </Button>
      </div>
      {showViewDetail ? (
        <div
          style={{
            flex: `0 1 ${viewDetailWidth}px`,
            display: "flex",
            justifyContent: "right",
            minWidth: viewDetailWidth,
            maxWidth: viewDetailWidth,
          }}
          title="View Detail"
        >
          <Link
            to={`${serviceBasePaths["web_client:device_management"]
              }?vehicle=${encodeURIComponent(vehicle.id)}`}
          >
            View Detail
          </Link>
        </div>
      ) : null}
    </div>
  );
};

const VehicleManagerPage = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentTenant } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addAlerts, currentOverlay, setOverlay } = useUI();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [dataState, setDataState] = React.useState({
    devices: {},
    loading: true,
    vehicles: {},
    vehiclesLookup: {},
  } as {
    devices: { [deviceId: string]: MobileDevice };
    loading: boolean;
    vehicles: { [vehicleId: string]: NormalizedVehicle };
    vehiclesLookup: { [vehicleKey: string]: string };
  });

  const doVehicleAction = async (
    vehicle: NormalizedVehicle,
    action: "register" | "edit"
  ) => {
    setDataState((prevDataState) => ({ ...prevDataState, loading: true }));

    try {
      const abortController = new AbortController();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      if (action === "register") {
        await registerNewVehicle({
          accessToken,
          currentTenant,
          signal: abortController.signal,
          vehicle,
        });
      } else {
        await editVehicle({
          accessToken,
          currentTenant,
          signal: abortController.signal,
          vehicle,
        });
      }
      setDataState((prevDataState) => ({
        ...prevDataState,
        loading: false,
        vehicles: {
          ...prevDataState.vehicles,
          [vehicle.id]: {
            ...vehicle,
          },
        },
      }));
      setOverlay("");
    } catch (error) {
      if (error === API_ABORT_MESSAGE) {
        console.warn(error);
      } else {
        console.error(error);
        addAlerts([
          {
            severity: "error",
            message: (error as any).toString(),
          },
        ]);
        setDataState((prevDataState) => ({
          ...prevDataState,
          loading: false,
        }));
      }
    }
  };

  const doDeviceAction = async (
    device: MobileDevice,
    action: "register" | "edit" | "remove"
  ) => {
    setDataState((prevDataState) => ({ ...prevDataState, loading: true }));

    try {
      const abortController = new AbortController();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      if (action === "register") {
        await registerNewDevice({
          accessToken,
          currentTenant,
          device,
          signal: abortController.signal,
        });
      } else if (action === "edit") {
        await editDevice({
          accessToken,
          currentTenant,
          device,
          signal: abortController.signal,
        });
      } else {
        await removeDevice({
          accessToken,
          currentTenant,
          device,
          signal: abortController.signal,
        });
      }
      setDataState((prevDataState) => {
        const vehicle = Object.values(prevDataState.vehicles).find(
          (vehicle) => vehicle.id === device.vehicleId
        ) as NormalizedVehicle;

        return {
          ...prevDataState,
          devices: {
            ...prevDataState.devices,
            [device.id]: {
              ...device,
            },
          },
          loading: false,
          sites: {
            ...prevDataState.vehicles,
            [vehicle.id]: {
              ...vehicle,
              deviceIds: [...(new Set([...vehicle.deviceIds, device.id]) as any)],
            },
          },
        };
      });
      setOverlay("");
    } catch (error) {
      if (error === API_ABORT_MESSAGE) {
        console.warn(error);
      } else {
        console.error(error);
        addAlerts([
          {
            severity: "error",
            message: (error as any).toString(),
          },
        ]);
        setDataState((prevDataState) => ({
          ...prevDataState,
          loading: false,
        }));
      }
    }
  };

  const vehicle = searchParams.get("vehicle") ?? "";
  const currentVehicle = currentOverlay.includes("Vehicle:")
    ? dataState.vehicles[currentOverlay.split(":")[1]]
    : dataState.vehicles[dataState.vehiclesLookup[vehicle]];
  const vehiclesList = Object.values(dataState.vehicles);
  const vehicleVins = vehiclesList.map((vehicle) => vehicle.vin);
  const searchResults = vehiclesList.filter(
    (vehicle) =>
      vehicle.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.vin?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.make?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.model?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.year?.toString().includes(searchTerm) ||
      vehicle.plate?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.fleetId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.deviceIds.find((deviceId) => {
        const device = dataState.devices[deviceId];

        return (
          device.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          device.make?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          device.model?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          device.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
  );

  const devicesList = Object.values(dataState.devices);
  const mmsDevices = devicesList.filter(
    (device) =>
      typeof device.make === "string" && typeof device.model === "string"
  );
  const mmsEnrolledDevices = mmsDevices.filter((device) => device.enrolled);
  const makeModelSerialNumbers = mmsDevices.map(createMmsString);
  const enrolledMakeModelSerialNumbers =
    mmsEnrolledDevices.map(createMmsString);

  const setVehicle = (newVehicle: string) => {
    setSearchParams((prevSearchParams) => {
      if (newVehicle === "") {
        prevSearchParams.delete("vehicle");
      } else {
        prevSearchParams.set("vehicle", newVehicle);
      }
      return prevSearchParams;
    });
  };

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadMobileDeviceManagerData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      try {
        const devicesAndVehicles = await fetchMobileAssets({
          accessToken,
          currentTenant,
          signal: abortController.signal,
        });
        setDataState({
          devices: devicesAndVehicles.devices,
          loading: false,
          vehicles: devicesAndVehicles.vehicles,
          vehiclesLookup: devicesAndVehicles.vehiclesLookup,
        });
      } catch (error) {
        if (error === API_ABORT_MESSAGE) {
          console.warn(error);
        } else {
          console.error(error);
        }
      }
    };

    loadMobileDeviceManagerData();

    return () => {
      abortController.abort(API_ABORT_MESSAGE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, currentTenant]);

  if (dataState.loading && !Object.keys(dataState.vehicles).length) {
    return <Loader />;
  }
  const wrapperStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  };

  if (dataState.loading) {
    wrapperStyle.cursor = "wait";
  }

  const { style, ...rest } = props;

  return (
    <div style={{ ...wrapperStyle, ...style }} {...rest}>
      {currentOverlay === "registerNewVehicle" ? (
        <RegisterNewVehicleModal
          confirmAction={async (vehicle) => {
            await doVehicleAction(vehicle, "register");
            window.location.reload();
          }}
          vehicleVins={vehicleVins}
        />
      ) : null}
      {currentOverlay.startsWith(`editVehicle:`) ? (
        <EditVehicleModal
          confirmAction={async (vehicle) => {
            if (vehicle.enrolled === true) {
              await doVehicleAction(vehicle, "edit");
            } else {
              setOverlay(`confirmUnenrollVehicle:${vehicle.id}`)
            }
          }}
          vehicle={currentVehicle}
          vehicleVins={vehicleVins}
        />
      ) : null}
      {currentOverlay.startsWith(`confirmUnenrollVehicle:`) ? (
        <VehicleUnenrollmentModal
          confirmAction={async (vehicle) => {
            vehicle.enrolled = false;
            await doVehicleAction(vehicle, "edit");
            window.location.reload();
          }}
          cancelAction={async (vehicle) => {
            setOverlay(`editVehicle:${vehicle.id}`)
          }}
          vehicle={currentVehicle}
          devices={currentVehicle.deviceIds.map((deviceId) => dataState.devices[deviceId])}
        />
      ) : null}
      {
        currentOverlay === "registerNewDevice" ? (
          <RegisterNewMobileDeviceModal
            confirmAction={async (device) => {
              await doDeviceAction(device, "register");
              window.location.reload();
            }}
            enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
            makeModelSerialNumbers={makeModelSerialNumbers}
            vehicle={currentVehicle}
          />
        ) : null
      }
      {
        currentOverlay.startsWith(`editDevice:`) ? (
          <EditMobileDeviceModal
            confirmAction={async (device) => {
              await doDeviceAction(device, "edit");
              window.location.reload();
            }}
            device={dataState.devices[currentOverlay.split(":")[1]]}
            enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
            makeModelSerialNumbers={makeModelSerialNumbers}
            vehicle={currentVehicle}
          />
        ) : null
      }
      {
        currentOverlay.startsWith(`removeDevice:`) ? (
          <RemoveMobileDeviceModal
            confirmAction={async (device) => {
              await doDeviceAction(device, "remove");
            }}
            device={dataState.devices[currentOverlay.split(":")[1]]}
          />
        ) : null
      }
      {vehicle ? (
        <>
          <Button
            className="inline return"
            onClick={() => setVehicle("")}
            style={{
              display: "flex",
              alignItems: "center",
              width: 90,
              minHeight: 28,
              marginTop: 16,
              marginBottom: 32,
              marginLeft: -8,
              fontSize: "16px",
            }}
            title="Return to list"
          >
            <img
              src={ArrowBackIcon}
              alt="Return to list"
              width={16}
              height={17}
            />{" "}
            <span style={{ marginLeft: 8 }}>Return</span>
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 40,
              marginBottom: 24,
            }}
          >
            <h2
              style={{
                fontSize: "34px",
                lineHeight: "34px",
              }}
            >
              Vehicle Detail
            </h2>
            {currentVehicle ? (
              <Button
                className="action lg"
                disabled={dataState.loading || currentVehicle.type === "Unknown"}
                onClick={() => setOverlay("registerNewDevice")}
              >
                REGISTER NEW DEVICE
              </Button>
            ) : null}
          </div>
          {currentVehicle ? (
            <div>
              <div
                style={{
                  display: "flex",
                  gap: 18,
                  padding: "12px 24px",
                  color: "#c6c6c6",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                <span style={{ flex: "0 1 100px", minWidth: 75 }}>
                  Fleet ID
                </span>
                <span style={{ flex: "0 1 160px", minWidth: 75 }}>
                  VIN
                </span>
                <span style={{ flex: "0 1 100px", minWidth: 75 }}>Make</span>
                <span style={{ flex: "0 1 100px", minWidth: 75 }}>Model</span>
                <span style={{ flex: "0 1 70px", minWidth: 70 }}>Year</span>
                <span style={{ flex: "0 1 70px", minWidth: 70 }}>Plate</span>
                <span style={{ flex: "0 1 250px", minWidth: 75 }}>Vehicle ID (generated)</span>
                <span style={{ flex: "0 1 25px", minWidth: 25 }}>
                  Enrolled Devices
                </span>
                <span
                  style={{
                    flex: `0 1 ${editVehicleWidth + viewDetailWidth}px`,
                    minWidth: editVehicleWidth + viewDetailWidth,
                    maxWidth: editVehicleWidth + viewDetailWidth,
                  }}
                />
              </div>
              <VehicleRow
                devices={dataState.devices}
                isLoading={dataState.loading}
                showViewDetail={false}
                vehicle={currentVehicle}
              />
              {currentVehicle.deviceIds.length ? (
                <>
                  <h2
                    style={{
                      marginTop: 32,
                      marginBottom: 24,
                      fontSize: "34px",
                      lineHeight: "34px",
                    }}
                  >
                    Device List
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      gap: 18,
                      padding: "12px 16px 12px 24px",
                      color: "#c6c6c6",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  >
                    <span style={{
                      flex: "0 1 260px",
                      minWidth: 50,
                      maxWidth: 260,
                    }}>Device ID</span>
                    <span style={{
                      flex: "0 1 260px",
                      minWidth: 50,
                      maxWidth: 260,
                    }}>
                      Vehicle ID (generated)
                    </span>
                    <span style={{
                      flex: "0 1 145px",
                      minWidth: 145,
                      maxWidth: 145,
                    }}>
                      Device Type
                    </span>
                    <span style={{
                      flex: "0 1 100px",
                      minWidth: 50,
                      maxWidth: 100,
                    }}>
                      Serial Number
                    </span>
                    <span style={{
                      flex: "0 1 104px",
                      minWidth: 50,
                      maxWidth: 110,
                    }}>Make</span>
                    <span style={{
                      flex: "0 1 126px",
                      minWidth: 50,
                      maxWidth: 130,
                    }}>Model</span>
                    <span
                      style={{
                        flex: "0 1 186px",
                        minWidth: 186,
                        maxWidth: 186,
                      }}
                    />
                  </div>
                  <div
                    style={{ height: "calc(100vh - 450px)", overflowY: "auto" }}
                  >
                    {currentVehicle.deviceIds
                      .sort((deviceIdA, deviceIdB) => {
                        const deviceA = dataState.devices[deviceIdA];
                        const deviceB = dataState.devices[deviceIdB];

                        if (
                          deviceA.changedAt === null ||
                          deviceB.changedAt === null
                        ) {
                          return deviceA.changedAt === deviceB.changedAt
                            ? 0
                            : deviceA.changedAt === null
                              ? 1
                              : -1;
                        }
                        return deviceA.changedAt < deviceB.changedAt ? 1 : -1;
                      })
                      .map((deviceId) => {
                        const device = dataState.devices[deviceId];

                        return (
                          <div
                            key={device.id}
                            style={{
                              marginBottom: "12px",
                              padding: "0 16px 0 24px",
                              borderRadius: "8px",
                              backgroundColor: "#1e1e1e",
                              fontSize: "14px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: 18,
                                alignItems: "center",
                                height: 75,
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              <div
                                style={{
                                  flex: "0 1 260px",
                                  minWidth: 50,
                                  maxWidth: 260,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                                title={device.id}
                              >
                                <span>{device.id}</span>
                              </div>
                              <div
                                style={{
                                  flex: "0 1 260px",
                                  minWidth: 50,
                                  maxWidth: 260,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                                title={device.vehicleId ?? "-"}
                              >
                                <span>{device.vehicleId ?? "-"}</span>
                              </div>
                              <div
                                style={{ flex: "0 1 145px", minWidth: 145, maxWidth: 145 }}
                                title={getMobileDeviceTypeLabel(device.deviceType)}
                              >
                                <span className="badge">
                                  {getMobileDeviceTypeLabel(device.deviceType)}
                                </span>
                              </div>
                              <div
                                style={{
                                  flex: "0 1 100px",
                                  minWidth: 50,
                                  maxWidth: 100,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                                title={device.serialNumber ?? "-"}
                              >
                                <span>
                                  {device.serialNumber ?? "-"}
                                </span>
                              </div>
                              <div
                                style={{
                                  flex: "0 1 104px",
                                  minWidth: 50,
                                  maxWidth: 110,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                                title={device.make ?? "-"}
                              >
                                <span>{device.make ?? "-"}</span>
                              </div>
                              <div
                                style={{
                                  flex: "0 1 126px",
                                  minWidth: 50,
                                  maxWidth: 130,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                                title={device.model ?? "-"}
                              >
                                <span>{device.model ?? "-"}</span>
                              </div>
                              <div
                                style={{
                                  flex: "0 1 186px",
                                  display: "flex",
                                  justifyContent: "right",
                                  minWidth: 186,
                                  maxWidth: 186,
                                  marginLeft: 10,
                                }}
                              >
                                <Button
                                  className="inline"
                                  disabled={dataState.loading}
                                  onClick={() =>
                                    setOverlay(`editDevice:${device.id}`)
                                  }
                                >
                                  Edit Device
                                </Button>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: 18,
                                alignItems: "center",
                                height: 51,
                                paddingTop: 2,
                                paddingBottom: 27,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flex: "0 1 240px",
                                  minWidth: 172,
                                  maxWidth: 240,
                                  fontSize: "16px",
                                }}
                              >
                                <span style={{ fontWeight: 700 }}>
                                  Device Status
                                </span>
                                <span
                                  style={{
                                    marginLeft: 16,
                                    minWidth: 122,
                                    maxWidth: 122,
                                    textAlign: "center",
                                    whiteSpace: "nowrap",
                                    padding: "2px 16px",
                                    borderRadius: 4,
                                    backgroundColor: device.enrolled
                                      ? "#198038"
                                      : "#393939",
                                    color: device.enrolled
                                      ? "#f4f4f4"
                                      : "#000000",
                                  }}
                                >
                                  {!device.enrolled ? "Not " : ""}
                                  Enrolled
                                </span>
                              </div>
                              <div
                                style={{
                                  flex: "0 1 837px",
                                  minWidth: 305,
                                  maxWidth: 837,
                                }}
                              ></div>
                              <div
                                style={{
                                  flex: "0 1 176px",
                                  display: "flex",
                                  justifyContent: "right",
                                  minWidth: 176,
                                  maxWidth: 176,
                                  marginLeft: 10,
                                }}
                              >
                                <Button
                                  className="inline"
                                  disabled={dataState.loading || true} // TODO: Enable once the API works
                                  onClick={() =>
                                    setOverlay(`removeDevice:${device.id}`)
                                  }
                                >
                                  Remove Device
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 374px)",
                  }}
                >
                  <h2 style={{ fontSize: "34px" }}>No enrolled Devices</h2>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <h2 style={{ fontSize: "34px" }}>Vehicle not found</h2>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: 40,
              marginBottom: 24,
            }}
          >
            <div style={{ display: "flex" }}>
              {vehiclesList.length ? (
                <Input
                  autoFocus
                  className={searchResults.length ? undefined : "error"}
                  placeholder="Search by Vehicle Make, Model, Year, etc."
                  type="search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  style={{ width: searchTerm ? 283 : 323 }}
                />
              ) : null}
              <Button
                className="action lg"
                onClick={() => setOverlay("registerNewVehicle")}
                style={{ marginLeft: 16 }}
              >
                REGISTER NEW VEHICLE
              </Button>
            </div>
          </div>
          {vehiclesList.length ? (
            <div
              style={{
                height: "calc(100% - 64px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 18,
                  padding: "12px 24px",
                  color: "#c6c6c6",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                <span style={{ flex: "0 1 100px", minWidth: 75 }}>
                  Fleet ID
                </span>
                <span style={{ flex: "0 1 160px", minWidth: 160 }}>
                  VIN
                </span>
                <span style={{ flex: "0 1 100px", minWidth: 75 }}>Make</span>
                <span style={{ flex: "0 1 100px", minWidth: 75 }}>Model</span>
                <span style={{ flex: "0 1 70px", minWidth: 70 }}>Year</span>
                <span style={{ flex: "0 1 70px", minWidth: 70 }}>Plate</span>
                <span style={{ flex: "0 1 250px", minWidth: 75 }}>Vehicle ID (generated)</span>
                <span style={{ flex: "0 1 25px", minWidth: 25 }}>
                  Enrolled Devices
                </span>
                <span
                  style={{
                    flex: `0 1 ${editVehicleWidth + viewDetailWidth}px`,
                    minWidth: editVehicleWidth + viewDetailWidth,
                    maxWidth: editVehicleWidth + viewDetailWidth,
                  }}
                />
              </div>
              <div style={{ overflowY: "scroll" }}>
                {searchResults.map((result) => (
                  <VehicleRow
                    devices={dataState.devices}
                    key={result.id}
                    isLoading={dataState.loading}
                    showViewDetail
                    vehicle={result}
                    setVehicle={setVehicle}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <h2 style={{ fontSize: "34px" }}>No registered Vehicles</h2>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default VehicleManagerPage;
