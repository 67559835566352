import React from "react";

const Select = (
  props: React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > & {
    changed?: boolean;
    label?: string;
    options: string[];
    warning?: string;
  }
) => {
  const {
    changed,
    className: providedClassName,
    disabled,
    label,
    options,
    required,
    title: providedTitle,
    warning,
    ...rest
  } = props;
  const classNames = [providedClassName ?? ""];
  let title = providedTitle;
  let errorText = "";

  if (disabled) {
    if (typeof providedTitle === "string") {
      title += " (disabled)";
    } else {
      title = "(disabled)";
    }
  }

  if (required && disabled !== true && !props.value?.toString().length) {
    errorText = `*${label} is required`;
  }

  if (errorText) {
    classNames.push("error");
  } else if (warning && disabled !== true) {
    classNames.push("warning");
  } else if (changed && disabled !== true) {
    classNames.push("changed");
  }
  return (
    <>
      {label ? (
        <label
          htmlFor={props.id}
          style={{
            display: "inline-block",
            marginBottom: 4,
            cursor: "pointer",
          }}
        >
          {label}
          {required ? <span className="urgent"> *</span> : null}
        </label>
      ) : null}
      <div className="input-wrapper">
        <select className={classNames.join(" ")} tabIndex={0} title={title} {...rest}>
          <option disabled selected style={{ display: "none" }} value="" />
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 4,
        }}
      >
        <span />
        {disabled ? null : errorText ? (
          <span className="urgent">{errorText}</span>
        ) : (
          <span className="warning">{warning}</span>
        )}
      </div>
    </>
  );
};
export default Select;
