import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { canAccessService, getPageTitle, getTenantName } from "../lib/auth";
import { useAuth } from "../state/authState";
import { useUI } from "../state/uiState";

import Button from "./controls/Button";

import config, { serviceBasePaths } from "../config";

import CheckIcon from "../assets/icons/check.svg";
import ChevronIcon from "../assets/icons/chevronDropdown.svg";

const Header = () => {
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading, logout } = useAuth0();
  const {
    allowedTenants,
    currentTenant,
    currentUser,
    getDebug,
    setCurrentTenant,
    toggleDebug,
  } = useAuth();
  const { currentOverlay, setOverlay } = useUI();

  const tenantName = getTenantName(currentTenant);
  const debug = getDebug();

  React.useEffect(() => {
    const titleParts = ["Panasonic Cirrus"];
    const rootElement = document.getElementById("root");

    if (isAuthenticated) {
      titleParts.unshift(getPageTitle(pathname));
    }
    document.title = titleParts.join(" | ");

    if (rootElement) {
      if (
        pathname.includes(serviceBasePaths["web_client:device_management"]) ||
        pathname.includes(serviceBasePaths["web_client:time_travel"]) ||
        pathname.includes(serviceBasePaths["web_client:user_management"])
      ) {
        rootElement.className = "no-img";
      } else if (!isLoading) {
        rootElement.className = "img";
      }
    }
  }, [isAuthenticated, isLoading, pathname]);

  return (
    <header>
      <Link
        to="/"
        style={{ display: "flex", alignItems: "center" }}
        title="Return to home page"
      >
        <img
          src={config.assets.headerLogo}
          alt="Cirrus by Panasonic logo"
          width={212}
          height={30}
        />
      </Link>
      {isAuthenticated && !isLoading ? (
        <button
          id="header-items"
          onClick={() =>
            setOverlay(currentOverlay === "profile" ? "" : "profile")
          }
          style={{ height: 40, cursor: "pointer" }}
        >
          <div id="profile-dropdown">
            <div id="profile-picture">
              {(config.tenants as any)[currentTenant]?.logo ? (
                <img
                  src={(config.tenants as any)[currentTenant].logo}
                  alt={`${tenantName} logo`}
                  width={26}
                />
              ) : null}
            </div>
          </div>
          <h2 className="tenant-name" style={{ marginLeft: 11 }}>
            {tenantName}
          </h2>
          <img
            src={ChevronIcon}
            alt={`The dropdown profile menu is ${currentOverlay === "profile" ? "open" : "closed"
              }`}
            width={12}
            height={12}
            style={{
              marginLeft: 14,
              transform: `rotate(${currentOverlay === "profile" ? 180 : 0}deg)`,
            }}
          />
          {currentOverlay === "profile" ? (
            <div className="menu" onClick={(e) => e.stopPropagation()}>
              <h3>Profile</h3>
              <Link
                onClick={() => setOverlay("")}
                style={{ height: 50, display: "flex", flexDirection: "column" }}
                to={`${serviceBasePaths["web_client:user_management"]
                  }?u=${encodeURIComponent(currentUser.id)}`}
              >
                <span>
                  {currentUser.firstName} {currentUser.lastName}
                </span>
                <span>{currentUser.emailAddress}</span>
              </Link>
              <div className="horizontal-separator" />
              {allowedTenants.length > 1 ? (
                <>
                  <h3>Tenant</h3>
                  {allowedTenants.map((tenant) => (
                    <Button
                      key={tenant}
                      onClick={() => setCurrentTenant(tenant)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{getTenantName(tenant)}</span>
                      {currentTenant === tenant ? (
                        <img
                          src={CheckIcon}
                          alt="Selected tenant"
                          width={12}
                          height={12}
                          title="Selected tenant"
                        />
                      ) : null}
                    </Button>
                  ))}
                  <div className="horizontal-separator" />
                </>
              ) : null}
              <h3>Tools</h3>
              {canAccessService(
                currentUser.authMetadata,
                "web_client:user_management"
              ) ? (
                <Link
                  onClick={() => setOverlay("")}
                  to={serviceBasePaths["web_client:user_management"]}
                >
                  <div style={{ height: 22, lineHeight: "22px" }}>
                    User Manager
                  </div>
                </Link>
              ) : null}
              <a href="mailto:v2xsupport@us.panasonic.com">Contact Us</a>
              <div className="horizontal-separator" />
              {debug !== "unauthorized" ? (
                <>
                  <Button
                    onClick={() => {
                      toggleDebug();
                      setOverlay("");
                    }}
                    style={{ height: 24 }}
                  >
                    {debug === "enabled" ? "Disable" : "Enable"} Debug Mode
                  </Button>
                  <div className="horizontal-separator" />
                </>
              ) : null}
              <Button
                onClick={() =>
                  logout({
                    logoutParams: { returnTo: window.location.href },
                  })
                }
                style={{ height: 24 }}
              >
                Log Out
              </Button>
            </div>
          ) : null}
        </button>
      ) : null}
    </header>
  );
};
export default Header;
